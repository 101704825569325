var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-container"},[_c('h1',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.siteName))]),_c('div',{staticClass:"bg-center bg-cover py-12 bg-homepage--video",style:(_vm.backgroundImageIfPresent(_vm.videoBackgroundImage))},[(_vm.isLoadingVideo)?_c('Spinners',{staticClass:"my-16"}):(_vm.featuredVideo && _vm.featuredVideo.videoUrl)?_c('div',{key:"db-video",staticClass:"container px-4"},[_c('video-component',{attrs:{"sessionData":{
                    sessionId: null,
                    title: _vm.featuredVideo.title
                },"options":{
                    autoplay: false,
                    controls: true,
                    sources: [
                        {
                            src: _vm.featuredVideo.videoUrl,
                            type: 'application/x-mpegURL'
                        }
                    ],
                    poster: _vm.featuredVideo.thumbnail
                }}})],1):(_vm.notProduction)?_c('div',{key:"default-video",staticClass:"container px-4 block m-auto"},[_c('div',{staticClass:"videoWrapper"},[_c('iframe',{staticClass:"video",attrs:{"src":"https://player.vimeo.com/video/403106834","width":"640","height":"564","title":"Introduction to Virtual Meeting Platform","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":"","muted":""}})])]):_vm._e()],1),(_vm.sectionToUse.staticPage.active)?_c('div',[_c('StaticPage',{attrs:{"staticPages":_vm.sectionToUse.staticPage.pages}})],1):_vm._e(),(_vm.isLoadingSessions)?_c('Spinners',{staticClass:"my-10"}):(
            _vm.sessionData.length && _vm.sectionToUse.recommendedStreams.useSection
        )?_c('div',{staticClass:"md:py-12 bg-cover bg-right",class:_vm.bgColor('previous'),style:(_vm.backgroundImageIfPresent(_vm.recommendedSectionBg))},[_c('div',{staticClass:"container py-3 px-4"},[_c('h2',{staticClass:"section-title text-center md:text-left home-prevsession-text-color"},[_vm._v(" "+_vm._s(_vm.homeLabel.previousSessionHeader ? _vm.homeLabel.previousSessionHeader : "PREVIOUS SESSIONS")+" ")]),_c('div',{staticClass:"wrapper"},[_c('SessionSwimLane',{attrs:{"sessionData":_vm.sessionData}})],1)])]):_vm._e(),(_vm.isLoadingFeaturedContent)?_c('Spinners',{staticClass:"my-10"}):(
            _vm.featuredContent &&
                _vm.featuredContent.length &&
                _vm.sectionToUse.featuredContentSwimlane.useSection
        )?_c('div',{staticClass:"md:py-12 bg-cover",class:_vm.bgColor('previous'),style:(_vm.backgroundImageIfPresent(_vm.recommendedSectionBg))},[_c('div',{staticClass:"container py-3 px-4"},[_c('h2',{staticClass:"section-title text-center md:text-left home-prevsession-text-color"},[_vm._v(" "+_vm._s(_vm.homeLabel.featuredContentHeader ? _vm.homeLabel.featuredContentHeader : "Featured")+" ")]),_c('div',{staticClass:"wrapper"},[_c('SessionSwimLane',{attrs:{"sessionData":_vm.featuredContent,"contentType":"featuredContent"}})],1)])]):_vm._e(),(_vm.sectionToUse.staticPage.pages[2].useSection)?_c('div',[_c('StaticPageIcons')],1):_vm._e(),(_vm.sectionToUse.twitterEmbed)?_c('section',{staticClass:"py-20 bg-primary grid",attrs:{"id":"twitter-timeline--embed"}},[_c('article',{staticClass:"w-full flex flex-col  md:flex-row container m-auto"},[_c('div',{staticClass:"w-full md:w-2/5 border-r-1 container px-6",domProps:{"innerHTML":_vm._s(_vm.sectionToUse.twitterEmbed)}}),_c('div',{staticClass:"ml-12 md:3/4"},_vm._l((_vm.attendeeSwimlane),function(att){return _c('div',{key:att.category},[_c('h4',{staticClass:"text-white text-2xl pb-4"},[_vm._v(" "+_vm._s(att.headerLabel)+" ")]),_c('div',{staticClass:"grid gap-6 md:grid-cols-3"},_vm._l((_vm.attendeeTier),function(attendee){return _c('AttendeeCard',{key:attendee.attendeeId,attrs:{"data":attendee}})}),1)])}),0)])]):_vm._e(),_c('AdSlot',{attrs:{"slot-name":"home"}}),(_vm.sectionToUse.attendeeSwimlane.useSection)?_c('section',{staticClass:"section px-4 bg-cover",class:_vm.cssVariables['--home-sampling-attendee-bg-color'] != null
                ? 'bg-attendee-swimlane'
                : 'bg-white',style:(_vm.backgroundImageIfPresent(_vm.featuredAttendeeSectionBg))},[_c('div',{staticClass:"container py-4"},[(_vm.getLayoutOptions.usesUtilityHeader)?_c('div',{staticClass:"mb-4"},[_c('svg-networking',{staticClass:"home-networking-icon"})],1):_vm._e(),(!_vm.featuredAttendeesLoaded)?_c('Spinners',{staticClass:"my-8"}):_vm._e(),(_vm.attendeeSwimlane.length)?_c('section',{staticClass:"container px-4 my-8"},_vm._l((_vm.attendeeSwimlane),function(swimlane){return _c('featured-attendee',{key:swimlane.category,attrs:{"header-label":swimlane.headerLabel,"header-centered":swimlane.headerCentered,"attendees":swimlane.data,"isSearchButtonActive":_vm.sectionToUse.attendeeSwimlane.isSearchButtonActive,"header-classes":"bg-attendee-swimlane-text"}})}),1):_vm._e()],1)]):_vm._e(),(_vm.sectionToUse.featuredSolutionProvider.useSection)?_c('section',{staticClass:"section featured-section",class:_vm.bgColor('featured-section')},[_c('div',{staticClass:"container px-4"},[_c(_vm.featuredSection,{tag:"component"})],1)]):_vm._e(),(_vm.sectionToUse.staticCompanyPartner)?_c('section',[_c('StaticPartnerPage')],1):_vm._e(),(_vm.sectionToUse.staticPage.pages[3].useSection)?_c('div',[_c('StaticPage',{attrs:{"bottomStaticPage":true}})],1):_vm._e(),(_vm.sectionToUse.ads.useSection)?_c('section',{staticClass:"py-12 md:px-4",style:(("background-color:" + (_vm.cssVariables['--ads-bg'])))},[_c('div',{staticClass:"container"},[_c('Ads')],1)]):_vm._e(),(_vm.sectionToUse.schedule.isActive)?_c('section',{staticClass:"container"},[_c('schedule',{attrs:{"noScrollOnDateChange":true}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }